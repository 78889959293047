import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, IconButton, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiTooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { createRoot } from 'react-dom/client';

import { News } from '../typings/news';

const Accordion = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    margin: '0px 20px',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0px 20px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '0 0',
    },
    alignItems: 'center',
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    display: 'block',
    padding: '0 20px 30px 20px',
    fontSize: '16px',
    lineHeight: '24px',
  },
}))(MuiAccordionDetails);

const Tooltip = withStyles({
  tooltip: {
    background: 'white',
    boxShadow: '0 0.25rem 0.5rem rgb(0 0 0 / 20%)',
    fontSize: '0.875rem',
    color: '#252525',
    borderRadius: 0,
    padding: '1rem',
    minWidth: 600,
  },
  arrow: {
    color: 'white',
  },
})(MuiTooltip);

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  newsForm: {
    '& .luSettings-MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 700,
    fontFamily: 'HaufeMerriweatherSans',
    fontSize: '1.2rem',
    flexGrow: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  cta: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '.8125rem',
    padding: '15px 22px',
    marginTop: 15,
    '& .luSettings-MuiSvgIcon-root': {
      marginLeft: 10,
    },
  },
  disabledHint: {
    fontSize: '.8rem',
    marginLeft: 10,
    fontWeight: 400,
  },
  newLabel: {
    fontWeight: 'normal',
    textTransform: 'uppercase',
    fontSize: '0.65rem',
    marginLeft: '0.5rem',
    color: 'white',
    backgroundColor: '#318551',
    padding: '0.25em 0.4em',
    position: 'relative',
    top: '-4px',
  },
  tooltipLink: {
    color: '#252525',
    fontWeight: 600,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:after': {
      content: '"?"',
      border: '1px solid #7c7c7c',
      height: '15px',
      width: '15px',
      borderRadius: '100px',
      display: 'inline-block',
      fontSize: '8px',
      color: '#7c7c7c',
      lineHeight: '15px',
      textAlign: 'center',
      position: 'relative',
      top: '-5px',
      marginLeft: '5px',
    },
  },
}));

type NewsAccordionItem = {
  index: number;
  news: News;
  expanded: string | false;
  totalItems: number;
  onEdit: (event: React.MouseEvent<HTMLButtonElement>, index: number, news?: News) => void;
  onMove: (event: React.MouseEvent<HTMLButtonElement>, from: number, to: number) => void;
  onDelete: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
  onExpand: (panel: string | false) => void;
};

const NewsAccordionItem: FunctionComponent<NewsAccordionItem> = ({
  index,
  news,
  expanded,
  totalItems,
  onEdit,
  onMove,
  onDelete,
  onExpand,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const tooltipLink = document.querySelectorAll(`.accordion-content-${index} a[href*="tooltip"]`);
    if (tooltipLink && news.tooltip) {
      tooltipLink.forEach((e) => {
        const newLink = document.createElement('span');
        const root = createRoot(newLink);
        root.render(
          <Tooltip title={news.tooltip?.text || ''} placement="top" arrow>
            <span className={classes.tooltipLink}>{e.textContent?.trim()}</span>
          </Tooltip>,
        );
        e.replaceWith(newLink);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    onExpand(newExpanded ? panel : false);
  };

  const { active, title, isNew, content, cta } = news;

  return (
    <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
      <AccordionSummary aria-controls="panel-content" id="panel-header">
        {expanded === `panel${index}` ? <ExpandMoreIcon /> : <NavigateNextIcon />}

        <Typography className={`${classes.title} ${active ? '' : classes.disabled}`}>
          {title}
          {isNew && <span className={classes.newLabel}>Neu</span>}
          {!active && <i className={classes.disabledHint}>{`(${t('deactivated')})`}</i>}
        </Typography>
        <Box>
          <IconButton
            color="primary"
            size="small"
            disabled={index <= 0}
            aria-label="up"
            component="span"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => onMove(event, index, index - 1)}
          >
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            disabled={index >= totalItems}
            aria-label="down"
            component="span"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => onMove(event, index, index + 1)}
          >
            <ArrowDownwardIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            aria-label="edit"
            component="span"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => onEdit(event, index, news)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            aria-label="delete"
            component="span"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => onDelete(event, index)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={active ? '' : classes.disabled}>
        {/* eslint-disable-next-line react/no-danger */}
        <span className={`accordion-content-${index}`} dangerouslySetInnerHTML={{ __html: content }} />
        {cta && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              component="button"
              className={classes.cta}
              onClick={() => window.open(cta?.url)}
            >
              {cta?.label} <OpenInNewIcon fontSize="small" />
            </Button>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export { NewsAccordionItem };
