/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { getApiService } from '../utils/apiRequest';

import { MicroFrontendContext } from './MicrofrontendContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  colorPicker: {
    marginBottom: theme.spacing(2),
    marginRight: '40px',
    '& .sample': {
      position: 'absolute',
      right: '-40px',
      bottom: 0,
      width: 24,
      height: 24,
      marginLeft: '.5rem',
    },
  },
  tabContent: {
    padding: theme.spacing(4),
    backgroundColor: '#e1e1e1',
  },
}));

enum Colors {
  'Pizazz' = '#FF9300',
  'Cerulean-2' = '#00ABF7',
  'wRazzmatazz-2' = '#ED1E79',
  'Persian Green-4' = '#03B9B6',
}

const NewsBanner: FunctionComponent<unknown> = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { getIdToken } = useContext(MicroFrontendContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [text, setText] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [color, setColor] = useState<string>(Colors['Cerulean-2']);

  const handleChangeColor = (event: React.ChangeEvent<{ value: unknown }>) => {
    setColor(event.target.value as string);
  };

  const handleChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.checked);
  };

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleChangeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  };

  const apiService = getApiService(getIdToken);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { result } = await apiService.getSettings('NewsBanner');
        const { enabled, text, link, color } = result[0].data;
        setEnabled(enabled);
        setText(text);
        setLink(link);
        setColor(color);
      } catch (error) {
        setError(`${t('failAlert')}: ${String(error)}`);
      }
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSnackbar = () => {
    setShowAlert(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await apiService.updateSettings({
        id: 'NewsBanner',
        data: {
          enabled,
          text,
          link,
          color,
        },
      });
      setShowAlert(true);
    } catch (error) {
      setError(`${t('failAlert')}: ${String(error)}`);
    }
    setLoading(false);
  };

  return (
    <Paper elevation={1} className={classes.tabContent}>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success">{t('successAlert')}</Alert>
      </Snackbar>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={5}>
          <Paper>
            {loading ? (
              <Box p={4} pt={8} pb={8} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <Box p={4} pt={4} pb={4} display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h2" style={{ margin: '0' }}>
                    {t('titleNewsBanner')}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={enabled}
                        onChange={handleChangeEnabled}
                        name="enabled"
                        color="primary"
                        value={enabled}
                      />
                    }
                    style={{ marginRight: 0 }}
                    label={t('form.enabled')}
                  />
                </Box>
                <TextField
                  id="textField"
                  label={t('form.text')}
                  placeholder={t('form.textPlaceholder')}
                  disabled={!enabled}
                  value={text}
                  onChange={handleChangeText}
                  className={classes.formControl}
                  multiline
                />
                <TextField
                  id="linkField"
                  label={t('form.link')}
                  placeholder={t('form.linkPlaceholder')}
                  onChange={handleChangeLink}
                  value={link}
                  className={classes.formControl}
                  disabled={!enabled}
                />
                <FormControl className={classes.colorPicker}>
                  <span className="sample" style={{ backgroundColor: enabled ? color : '#DDD' }} />
                  <InputLabel id="colorFieldLabel" disabled={!enabled}>
                    {t('form.color')}
                  </InputLabel>
                  <Select
                    labelId="colorFieldLabel"
                    onChange={handleChangeColor}
                    value={color}
                    id="colorField"
                    disabled={!enabled}
                  >
                    {Object.keys(Colors).map((e: string, i: number) => {
                      const value = Colors[e as keyof typeof Colors];
                      return (
                        <MenuItem key={i} value={value}>
                          <span style={{ width: 24, height: 24, backgroundColor: value, marginRight: '.5rem' }} />
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Box pt={3} display="flex" justifyContent="end">
                  <Button variant="contained" color="secondary" component="button" onClick={handleSubmit}>
                    {t('save')}
                  </Button>
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export { NewsBanner };
