type EnvironmentSpecificConfig = {
  API_URL: string
  API_PATH_PREFIX: string
}

const dev: EnvironmentSpecificConfig = {
  API_URL: 'http://localhost:8088',
  API_PATH_PREFIX: 'dev',
}
const test: EnvironmentSpecificConfig = {
  API_URL: 'https://f95f1wcxbg.execute-api.eu-central-1.amazonaws.com',
  API_PATH_PREFIX: 'test',
}

const prod: EnvironmentSpecificConfig = {
  API_URL: 'https://upoda4my4c.execute-api.eu-central-1.amazonaws.com',
  API_PATH_PREFIX: 'prod',
}

const env = process.env.REACT_APP_STAGE || 'dev'

const config = env === 'test'
  ? test
  : env === 'prod'
    ? prod
    : dev

const mergedConfig = {
  ...config,
  STANDALONE: env === 'dev'
}

export default mergedConfig;
