import { createRoot } from 'react-dom/client'

import { Microfrontend, Permission } from '@hmg-aka-lu/cockpit-microfrontend'

import './i18n';

import './theme/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import LUSettings from './components/LUSettings';
import mergedConfig from './config';
import { FunctionComponent } from 'react';
import MicroFrontendContext from './components/MicrofrontendContext';
declare global {
  interface Window {
    luSettings: Microfrontend;
  }
}

const LUSettingsFrontend: FunctionComponent<{getIdToken: () => Promise<string>, permissions: Permission[]}> = ({getIdToken, permissions}) => {
  return (
    <MicroFrontendContext.Provider value={{getIdToken, permissions: permissions}}>
      <LightHouseThemeProvider>
        <LUSettings />
      </LightHouseThemeProvider>
    </MicroFrontendContext.Provider>
  )
};

const microFrontendInstance: Microfrontend = (containerId = 'root', props) => {
  const rootElement = document.getElementById(containerId)
  if (!rootElement) {
    throw new Error('DOM root node not found')
  }

  return {
    render: () => {
      createRoot(rootElement).render(<LUSettingsFrontend getIdToken={props.getIdToken} permissions={props.permissions} />)
    }
  }
}

window.luSettings = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance('root', {permissions: ['Superuser'], getIdToken: async () => ''})
  microFrontend.render();
}
