import axios, { Method } from 'axios';

import { mergedConfig } from '../config';

export const getApiService = (getIdToken: () => Promise<string>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSettings = async (requestData: any) => {
    try {
      const endpoint = 'put-settings';
      const path = `/${mergedConfig.API_PATH_PREFIX}/${endpoint}`;
      const host = mergedConfig.API_URL.replace('https://', '');
      const method: Method = 'PUT';
      const options = {
        host,
        path,
        url: `${mergedConfig.API_URL}${path}`,
        headers: {
          ...{ 'Content-Type': 'application/json' },
          authorization: `Bearer ${await getIdToken()}`,
        } as Record<string, string>,
        method,
        body: JSON.stringify(requestData),
        data: requestData,
      };

      return (await axios(options)).data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('=== api request error ===', error);
      return requestData;
    }
  };

  const getSettings = async (pk: string) => {
    try {
      const endpoint = 'get-settings';
      const path = `/${mergedConfig.API_PATH_PREFIX}/${endpoint}?pk=${pk}`;
      const host = mergedConfig.API_URL.replace('https://', '');
      const method: Method = 'GET';
      const options = {
        host,
        path,
        url: `${mergedConfig.API_URL}${path}`,
        headers: {
          authorization: `Bearer ${await getIdToken()}`,
        } as Record<string, string>,
        method,
      };

      return (await axios(options)).data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('=== api request error ===', error);
      return error;
    }
  };

  return {
    updateSettings,
    getSettings,
  };
};
