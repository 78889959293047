import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Paper, Snackbar, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { getApiService } from '../utils/apiRequest';
import Alert from '@material-ui/lab/Alert';
import { News } from '../typings/news';
import NewsAccordionForm from './NewsAccordionForm';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import NewsAccordionItem from './NewsAccordionItem';
import MicroFrontendContext from './MicrofrontendContext';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(4),
    backgroundColor: '#e1e1e1',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-redeclare
const NewsAccordion: FunctionComponent<unknown> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { getIdToken } = useContext(MicroFrontendContext)
  const [newsList, setNewsList] = useState<News[]>([]);
  const [form, setForm] = useState<any>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showConfirmationDelete, setShowConfirmationDelete] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [itemToDelete, setItemToDelete] = useState<number | null>();
  const [expanded, setExpanded] = useState<string | false>(false);

  const apiService = getApiService(getIdToken);

  const handleSubmitNews = (index: number, news: News) => {
    let items = [...newsList];
    items[index] = news;
    handleSubmit(items);
    setShowForm(false);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const settings = await apiService.getSettings('NewsAccordion');
        const result = settings.result[0];
        setNewsList(result.data);
      } catch (error) {
        setError(`${t('failAlert')}: ${String(error)}`);
      }
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (newsList: News[]) => {
    setNewsList(newsList);
    setLoading(true);
    try {
      await apiService.updateSettings({
        id: 'NewsAccordion',
        data: newsList,
      });
      setShowAlert(true);
    } catch (error) {
      setError(`${t('failAlert')}: ${String(error)}`);
    }

    setLoading(false);
  };

  const handleSnackbar = () => {
    setShowAlert(false);
  };

  const handleDelete = () => {
    setShowConfirmationDelete(false);
    if (itemToDelete || itemToDelete === 0) {
      let items = [...newsList];
      items.splice(itemToDelete, 1);
      handleSubmit(items);
      setItemToDelete(null);
    }
  };

  const closeModal = () => {
    setShowForm(false);
  };

  const closeConfirmationDeleteModal = () => {
    setShowConfirmationDelete(false);
    setItemToDelete(null);
  };

  const handleOpenConfirmationDelete = (event: any, index: number) => {
    event.stopPropagation();
    event.preventDefault();

    setShowConfirmationDelete(true);
    setItemToDelete(index);
  };

  const handleOpenForm = (event: any, index: number, news?: News) => {
    event.stopPropagation();
    event.preventDefault();

    setForm(<NewsAccordionForm index={index} news={news} onSubmit={handleSubmitNews} onModalClose={closeModal} />);
    setShowForm(true);
  };

  const moveItem = (event: any, from: number, to: number) => {
    event.stopPropagation();
    event.preventDefault();

    setExpanded(`panel${to}`);
    let items = [...newsList];
    const f = items.splice(from, 1)[0];
    items.splice(to, 0, f);
    handleSubmit(items);
  };

  return (
    <Box className={classes.wrapper}>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success">{t('successAlert')}</Alert>
      </Snackbar>

      <Dialog
        fullScreen={fullScreen}
        open={showForm}
        maxWidth={'lg'}
        fullWidth
        onClose={() => setShowForm(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>{form}</DialogContent>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={showConfirmationDelete}
        maxWidth={'lg'}
        onClose={() => setShowConfirmationDelete(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{t('confirmationDeleteMsg')}</DialogTitle>
        <DialogContent />
        <DialogActions>
          <Button autoFocus onClick={closeConfirmationDeleteModal} color="primary">
            {t('cancel')}
          </Button>
          <Button color="primary" onClick={handleDelete} autoFocus>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={1}>
        {loading ? (
          <Box p={4} pt={8} pb={8} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : newsList.length > 0 ? (
          newsList.map((item, i) => (
            <NewsAccordionItem
              key={i}
              index={i}
              news={item}
              totalItems={newsList.length}
              expanded={expanded}
              onExpand={setExpanded}
              onMove={moveItem}
              onEdit={handleOpenForm}
              onDelete={handleOpenConfirmationDelete}
            />
          ))
        ) : (
          <Alert severity="info">Keine Meldungen gespeichert.</Alert>
        )}
      </Paper>

      <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} mt={2}>
        <Button variant="contained" color="secondary" component="button" onClick={(event: any) => handleOpenForm(event, newsList.length)}>
          {t('new')}
        </Button>
      </Box>
    </Box>
  );
};

export default NewsAccordion;
