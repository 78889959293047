export const i18nEn = {
  common: {
    cancel: 'Cancel',
    search: 'Search',
  },
  title: 'Badges',
  form: {
    createMappingButton: 'Create mapping',
    seminar_id_label: 'Seminar-ID (SAP)',
    seminar_id_description: 'Enter a Seminar-ID to see existing or create new badge mappings',
    badgeClassDescription: 'Badge class',
    validFrom: 'Valid from',
    pleaseSelectBadgeClass: '--- Please select a badge class ---',
  },
  existingMappingsHeader: 'Existing badge mappings for: {{title}}',
  noMappings: 'No badge mappings found',
  createMappingHeader: 'Create new badge mapping for: {{title}}',
  tabs: {
    badgeMapping: 'Badge Mappings',
    badgeGenerator: 'Badge-Generator',
    badgeUI: 'Badge-UI',
  },
  mappingTable: {
    headers: {
      image: 'Badge image',
      className: 'Badge class',
      validFrom: 'Valid from',
    },
  },
  createMapping: 'Create mapping',
  createMappingDialogTitle: 'Create new mapping',
  errors: {
    invalidSeminarId: 'Invalid Seminar-ID',
    noBadgeMappings: 'No badge mappings found',
  },
  badgeGenerator: {
    exportButtonLabel: 'Create badge image',
    form: {
      title1: 'Title 1',
      title2: 'Title 2',
      type: 'Style',
      iconCategory: 'Icon category',
      selectIcon: 'Choose icon',
      noCategorySelected: 'No category selected',
    },
  },
  badgeUI: {
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non enim accumsan, gravida orci eu, tempus quam. Praesent molestie urna eu mauris iaculis dignissim. Nam porta.',
    buttonLabel: 'Badge UI',
  },
  formatDate: 'dd.MM.yyyy',
};
