import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import { News } from '../typings/news';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ColorPalette } from '../theme/ColorPalette';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  newsForm: {
    '& .luSettings-MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
  colorPicker: {
    marginBottom: theme.spacing(2),
    marginRight: '40px',
    '& .sample': {
      position: 'absolute',
      right: '-40px',
      bottom: 0,
      width: 24,
      height: 24,
      marginLeft: '.5rem',
    },
  },
  editor: {
    borderBottom: `1px solid ${ColorPalette.monochrome.grey60}`,
    ...theme.typography.body1,
    '& .public-DraftStyleDefault-block': {
      margin: '0.25rem 0',
    },
    '& .public-DraftEditorPlaceholder-root': {
      ...theme.typography.body1,
    },
  },
  editorError: {
    borderBottomColor: ColorPalette.colors.crimson,
    borderBottomWidth: 2,
    '& .public-DraftEditorPlaceholder-root': {
      color: ColorPalette.colors.crimson,
    },
  },
  editorErrorText: {
    color: ColorPalette.colors.crimson,
    fontSize: '0.75rem',
    marginTop: '0.25rem',
  },
}));

type NewsAccordionFormProps = {
  index: number;
  news?: News;
  onSubmit: (index: number, news: News) => void;
  onModalClose: () => void;
};

const NewsAccordionForm: FunctionComponent<NewsAccordionFormProps> = ({ index, news, onSubmit, onModalClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [title, setTitle] = useState<string | undefined>(news?.title);
  const [titleError, setTitleError] = useState<boolean>(false);
  const [content, setContent] = useState<string | undefined>(news?.content);
  const [contentError, setContentError] = useState<boolean>(false);
  const [ctaLabel, setCtaLabel] = useState<string | undefined>(news?.cta?.label);
  const [ctaUrl, setCtaUrl] = useState<string | undefined>(news?.cta?.url);
  const [ctaLabelError, setCtaLabelError] = useState<boolean>(false);
  const [ctaUrlError, setCtaUrlError] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(news?.active || false);
  const [isNew, setIsNew] = useState<boolean>(news?.isNew || false);
  const [isDefaultOpen, setIsDefaultOpen] = useState<boolean>(news?.isDefaultOpen || false);
  const [cta, setCta] = useState<boolean>(!!news?.cta || false);
  const [tooltip, setTooltip] = useState<boolean>(!!news?.tooltip || false);
  const [tooltipText, setTooltipText] = useState<string | undefined>(news?.tooltip?.text);
  const [tooltipTextError, setTooltipTextError] = useState<boolean>(false);

  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML(news?.content || '');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  });

  const handleEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    setContent(draftToHtml(rawContentState));
    setContentError(!editorState.getCurrentContent().hasText());
  };

  const handleChange =
    (
      setter: React.Dispatch<React.SetStateAction<string | undefined>>,
      setError: React.Dispatch<React.SetStateAction<boolean>>,
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setter(value);
      setError(value.trim() === '');
    };

  const handleSubmit = () => {
    let hasError = false;

    if (!title) {
      setTitleError(true);
      hasError = true;
    }

    if (!content || !editorState.getCurrentContent().hasText()) {
      setContentError(true);
      hasError = true;
    }

    if (cta) {
      if (!ctaLabel) {
        setCtaLabelError(true);
        hasError = true;
      }
      if (!ctaUrl) {
        setCtaUrlError(true);
        hasError = true;
      }
    }

    if (tooltip) {
      if (!tooltipText) {
        setTooltipTextError(true);
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }

    onSubmit(index, {
      ...news,
      title: title!,
      content: content!,
      active,
      isNew,
      isDefaultOpen,
      cta: cta
        ? {
            label: ctaLabel!,
            url: ctaUrl!,
          }
        : null,
      tooltip: tooltip
        ? {
            text: tooltipText!,
          }
        : null,
    });
  };

  return (
    <Box
      style={{ minWidth: 800 }}
      p={2}
      pt={2}
      pb={2}
      display="flex"
      flexDirection="column"
      className={classes.newsForm}
    >
      <Box pb={1} display="flex" justifyContent="end">
        <FormControlLabel
          control={
            <Checkbox
              checked={active}
              onChange={(value) => setActive(value.target.checked)}
              name="active"
              color="primary"
              value={active}
            />
          }
          label={t('accordionForm.activated')}
        />
      </Box>

      <TextField
        id="title"
        label={t('accordionForm.title')}
        defaultValue={title}
        onChange={handleChange(setTitle, setTitleError)}
        required
        error={titleError}
        helperText={titleError ? t('accordionForm.titleError') : ''}
      />

      <Box mb={1.6}>
        <Editor
          editorState={editorState}
          editorClassName={`${classes.editor} ${contentError ? classes.editorError : ''}`}
          onEditorStateChange={handleEditorStateChange}
          placeholder={`${t('accordionForm.content')}*`}
          toolbar={{
            options: ['inline', 'list', 'textAlign', 'link', 'remove', 'history'],
          }}
        />
        {contentError && <Box className={classes.editorErrorText}>{t('accordionForm.contentError')}</Box>}
      </Box>

      <Box pb={3} display="flex" justifyContent="start" flexDirection="column">
        <FormControlLabel
          control={
            <Checkbox
              checked={isDefaultOpen}
              onChange={(value) => setIsDefaultOpen(value.target.checked)}
              name="isDefaultOpen"
              color="primary"
              value={isDefaultOpen}
            />
          }
          label={t('accordionForm.openByDefault')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isNew}
              onChange={(value) => setIsNew(value.target.checked)}
              name="isNew"
              color="primary"
              value={isNew}
            />
          }
          label={t('accordionForm.newLabel')}
        />
      </Box>

      <Grid container spacing={5}>
        <Grid item xs={12} lg={6}>
          <Box pb={1} display="flex" justifyContent="start" flexDirection="column">
            <FormControlLabel
              style={{ marginBottom: '20px' }}
              control={
                <Checkbox
                  checked={cta}
                  onChange={(value) => setCta(value.target.checked)}
                  name="enabled"
                  color="primary"
                  value={cta}
                />
              }
              label={t('accordionForm.cta')}
            />

            <TextField
              id="ctaLabel"
              disabled={!cta}
              label={t('accordionForm.ctaLabel')}
              defaultValue={ctaLabel}
              onChange={handleChange(setCtaLabel, setCtaLabelError)}
              required
              error={cta && ctaLabelError}
              helperText={cta && ctaLabelError ? t('accordionForm.ctaLabelError') : ''}
            />
            <TextField
              id="ctaUrl"
              disabled={!cta}
              label={t('accordionForm.ctaUrl')}
              defaultValue={ctaUrl}
              onChange={handleChange(setCtaUrl, setCtaUrlError)}
              required
              error={cta && ctaUrlError}
              helperText={cta && ctaUrlError ? t('accordionForm.ctaUrlError') : ''}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box pb={1} display="flex" justifyContent="start" flexDirection="column">
            <FormControlLabel
              style={{ marginBottom: '20px' }}
              control={
                <Checkbox
                  checked={tooltip}
                  onChange={(value) => setTooltip(value.target.checked)}
                  name="enabled"
                  color="primary"
                  value={tooltip}
                />
              }
              label={t('accordionForm.tooltip')}
            />

            <TextField
              id="tooltipText"
              multiline
              disabled={!tooltip}
              label={t('accordionForm.tooltipText')}
              defaultValue={tooltipText}
              onChange={handleChange(setTooltipText, setTooltipTextError)}
              required
              error={tooltip && tooltipTextError}
              helperText={tooltip && tooltipTextError ? t('accordionForm.tooltipTextError') : ''}
            />
          </Box>
        </Grid>
      </Grid>

      <Box pt={3} display="flex" justifyContent="end">
        <Button
          variant="outlined"
          color="primary"
          component="button"
          style={{ marginRight: '15px' }}
          onClick={() => onModalClose()}
        >
          {t('cancel')}
        </Button>
        <Button variant="contained" color="secondary" component="button" onClick={handleSubmit}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export { NewsAccordionForm };
